// Override default variables before the import
$body-bg: #EAD1A8;
$font-family-base: "Lucida Console", Monaco5;
//$font-size-base: 240;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

html {
    font-size: 2em;
    box-sizing: border-box;
}

@media screen and (max-width: 767px) {
    html {
        font-size: 1.25em;
    }
}

body {
    color: #111111;
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, 
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1em;
}

section {
    display: block;
    margin: .5em;
}

split {
    display: flex;
    column-gap: .5em;
}

split * {
    width: 50%;
    background: lightblue;
}

.emphasized {
    font-style: italic;
    font-weight: bolder;
}

.centered {
    text-align: center;
}

.app-name {
    font-weight: 700;
    font-family: Arial;
}

.app-logo {
    width: 75vmin;
    margin: auto;
    display: block;
}
